import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Styles
import { FormCSS } from 'styles/form'

// Images
import Arrow from 'img/arrow-circle-secondary.inline.svg'
import Location from 'img/location.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'
import Fax from 'img/fax.inline.svg'
import NavLink from 'components/shared/NavLink'
import Plaatjie from '@ubo/plaatjie'

// Hooks
import useLayout from 'hooks/useLayout'

// Interface
import { FormProps } from './FormShell'

const StyledFormDuuf = styled(FormDuuf)`
  background-color: ${({ theme }) => theme.color.light};
  z-index: 2;
  ${FormCSS}

  .form-duuf-confirm .gform_confirmation_message {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 576px) {
    padding: 5rem 2rem;
  }

  @media (max-width: 575.98px) {
    padding: 1rem;
  }
`

const Content = styled(ParseContent)`
  & p {
    margin-bottom: 0 !important;
    line-height: ${({ theme }) => theme.font.size.bigger};
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
    }
  }
`

const Details = styled.div`
  color: ${({ theme }) => theme.color.primary};

  & a:hover {
    text-decoration: underline;
  }
`

const Map = styled.div`
  & svg {
    width: 69px;
    height: 69px;
  }

  @media (max-width: 575.98px) {
    & svg {
      width: 32px;
      height: 32px;
    }
  }
`

const Pointer = styled.div<{ x: number; y: number; open?: boolean }>`
  ${(props) =>
    props.x &&
    props.y &&
    css`
      top: ${props.y}%;
      left: ${props.x}%;
    `}

  & svg {
    width: 100%;
    & path {
      fill: #808080;
    }

    &:hover path {
      fill: ${({ theme }) => theme.color.secondary};
    }

    @media (max-width: 991.98px) {
      height: 52px;
    }

    @media (min-width: 992px) {
      height: 69px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.open &&
    css`
      & svg {
        position: relative;
        z-index: 2;
        pointer-events: none;
        & path {
          fill: #105e94;
        }
      }
    `}
`

const SVGWrapper = styled(motion.div)`
  & svg {
    width: 32px;
    height: 32px;
  }
`

const Detail = styled.div`
  border-top: 2px solid #cfcfcf;
  border-bottom: 2px solid #cfcfcf;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 7.5px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const Title = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  &:hover {
    cursor: pointer;

    & span {
      text-decoration: underline;
    }
  }

  & svg {
    width: 32px;
    height: 32px;
    & path {
      fill: unset;
    }
  }
`

const FormContact: React.FC<FormProps> = ({ fields, location }) => {
  const linkIndex = location.hash.replace('#', '')

  useEffect(() => {
    if (typeof window !== `undefined` && linkIndex) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [])

  const [currentIndex, setCurrentIndex] = useState(Number(linkIndex) || 0)
  const layout = useLayout()

  return (
    <section>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-11">
            <StyledFormDuuf
              id={layout.locale === 'en_US' ? 1 : 3}
              className="position-relative mt-5"
              generate={false}
              privacyUrl="/privacy-policy/"
              privacyUrlPlacement="privacy policy"
            >
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-md-7">
                      <FormDuufGroup hideIds={[2, 3]} />
                    </div>
                    <div className="col-md-5 d-flex flex-column justify-content-between">
                      <div>
                        <FormDuufGroup showIds={[2, 3]} />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>* are required fields</span>
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledFormDuuf>
          </div>
        </div>
        {/* <div className="row align-items-center mt-5" id="locations">
          <div className="col-lg-8">
            <Map className="position-relative">
              <Plaatjie
                image={fields.map?.image}
                alt=""
                className="w-100 py-5"
              />
              {fields.map?.pointers?.map((pointer, pointerIndex) => (
                // <NavLink key={pointerIndex} to={pointer?.link?.url || '#'}>
                <Pointer
                  className="position-absolute"
                  x={pointer?.x || 0}
                  y={pointer?.y || 0}
                  open={currentIndex === pointerIndex}
                  onClick={() => setCurrentIndex(pointerIndex)}
                >
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3051 0.70752C7.17236 0.70752 3.81006 4.06982 3.81006 8.20254C3.81006 13.3969 11.3125 22.27 11.3125 22.27C11.3125 22.27 18.8001 13.1415 18.8001 8.20254C18.8001 4.06982 15.4379 0.70752 11.3051 0.70752ZM13.5665 10.3971C12.9429 11.0205 12.1241 11.3323 11.3051 11.3323C10.4862 11.3323 9.6671 11.0205 9.04381 10.3971C7.79683 9.15024 7.79683 7.12139 9.04381 5.87441C9.64762 5.27034 10.4508 4.93763 11.3051 4.93763C12.1593 4.93763 12.9624 5.27047 13.5665 5.87441C14.8135 7.12139 14.8135 9.15024 13.5665 10.3971Z"
                      fill="white"
                    />
                  </svg>
                </Pointer>

                // </NavLink>
              ))}
            </Map>
          </div>
          <div className="col-lg-4">
            <Content content={fields.description} className="py-5" />
            <AnimatePresence>
              <Details>
                {fields.details?.map((detail, detailIndex) => (
                  <Detail className="mb-3">
                    <Title
                      className="d-flex align-items-center justify-content-between py-3"
                      onClick={() => setCurrentIndex(detailIndex)}
                    >
                      <span className="mb-2">{detail?.title}</span>
                      <SVGWrapper
                        animate={
                          currentIndex === detailIndex
                            ? { rotate: 180 }
                            : { rotate: 0 }
                        }
                      >
                        <Arrow />
                      </SVGWrapper>
                    </Title>
                    {currentIndex === detailIndex && (
                      <AnimatePresence>
                        <div className="row">
                          <div className="mb-3" key={detailIndex}>
                            <div className="d-flex align-items-start">
                              <Location />
                              <Content content={detail?.location} />
                            </div>
                          </div>
                        </div>
                        {(detail?.phone ||
                          detail?.email ||
                          detail?.fax ||
                          detail?.extraemail) && (
                          <div>
                            {detail?.phone && (
                              <NavLink
                                className="d-flex align-items-center mb-2"
                                to={detail.phone?.url || '#'}
                              >
                                <Phone />
                                <span>{detail?.phone?.title}</span>
                              </NavLink>
                            )}
                            {detail?.fax && (
                              <NavLink
                                className="d-flex align-items-center mb-2"
                                to={detail.fax?.url || '#'}
                              >
                                <Fax />
                                <span>{detail?.fax?.title}</span>
                              </NavLink>
                            )}
                            <NavLink
                              className="d-flex align-items-center mb-2"
                              to={detail.email?.url || '#'}
                            >
                              <Email />
                              <span>{detail?.email?.title}</span>
                            </NavLink>
                            {detail.extraemail?.url && (
                              <NavLink
                                className="d-flex align-items-center mb-2"
                                to={detail.extraemail?.url || '#'}
                              >
                                <Email />
                                <span>{detail?.extraemail?.title}</span>
                              </NavLink>
                            )}
                            {detail.extraextraemail?.url && (
                              <NavLink
                                className="d-flex align-items-center mb-2"
                                to={detail.extraextraemail?.url || '#'}
                              >
                                <Email />
                                <span>{detail?.extraextraemail?.title}</span>
                              </NavLink>
                            )}
                          </div>
                        )}
                      </AnimatePresence>
                    )}
                  </Detail>
                ))}
              </Details>
            </AnimatePresence>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default FormContact
